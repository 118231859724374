import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { IMAGE_SERVER_URL } from "@/store/shared/ApiRequest";
import Blocks from "@/components/shared/Blocks.vue";
import UserShowcase from "@/components/user/showcase/UserShowcase.vue";
import EventModule from "@/store/module/shared/EventModule";
import GroupModule from "@/store/module/shared/GroupModule";
import UserModule from "@/store/module/user/UserModule";
let UserPage = class UserPage extends Vue {
    get user() {
        return this.$store.getters[UserModule.types.getters.USER];
    }
    get eventsTable() {
        return this.$store.getters[EventModule.types.getters.EVENTS_TABLE];
    }
    get groupsTable() {
        return this.$store.getters[GroupModule.types.getters.GROUPS_TABLE];
    }
    get participatedEvents() {
        if (!this.user || Object.keys(this.eventsTable).length === 0)
            return [];
        return this.user.eventsParticipant.map((p) => this.eventsTable[p.eventId]);
    }
    get participatedGroups() {
        if (!this.user || Object.keys(this.groupsTable).length === 0)
            return [];
        return this.user.groupsParticipant.map((p) => this.groupsTable[p.groupId]);
    }
    get groupsBlocks() {
        console.log(this.participatedGroups);
        return this.participatedGroups.map((group) => ({
            image: `${IMAGE_SERVER_URL}/public/${group.logo}`,
            href: `/group/${group.id}`,
            title: group.shortName ? group.shortName : group.fullName,
        }));
    }
    get eventsBlocks() {
        return this.participatedEvents.map((event) => ({
            image: `${IMAGE_SERVER_URL}/public/${event.logo}`,
            href: `/event/${event.id}`,
            title: event.name,
        }));
    }
    async mounted() {
        await Promise.all([
            this.$store.dispatch(GroupModule.types.actions.GET_GROUPS),
            this.$store.dispatch(EventModule.types.actions.GET_EVENTS),
        ]);
    }
};
UserPage = __decorate([
    Component({
        components: {
            Blocks,
            UserShowcase,
        },
    })
], UserPage);
export default UserPage;

import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { IMAGE_SERVER_URL } from "@/store/shared/ApiRequest";
import moment from "moment-timezone";
import UserModule from "@/store/module/user/UserModule";
import ApproveDialog from "@/components/shared/Approve.vue";
let UserShowcase = class UserShowcase extends Vue {
    constructor() {
        super(...arguments);
        this.fillProfileDialog = {
            show: false,
        };
    }
    get user() {
        return this.$store.getters[UserModule.types.getters.USER];
    }
    get avatar() {
        if (!this.user)
            return require("@/assets/default-avatar.jpg");
        if (this.user && this.user.avatar) {
            return `${IMAGE_SERVER_URL}/public/${this.user.avatar}`;
        }
        else {
            return `${IMAGE_SERVER_URL}/public/user-placeholder-${this.user.gender}.jpg`;
        }
    }
    get isSportsman() {
        return this.user.profile && this.user.profile.sportsman && this.user.profile.sportsman.length > 0;
    }
    get isJudge() {
        return this.user.profile && this.user.profile.judge && !!this.user.profile.judge.category;
    }
    get isCoach() {
        return this.$store.getters[UserModule.types.getters.iS_COACH];
    }
    get amplua() {
        let roles = [];
        if (this.user.isNationalTeamMember)
            roles.push("Член сборной");
        if (this.isSportsman)
            roles.push("Спортсмен");
        if (this.isJudge)
            roles.push("Судья");
        if (this.isCoach)
            roles.push("Тренер");
        return roles.length > 0 ? `(${roles.join(", ")})` : "(Член РФСЛ)";
    }
    get title() {
        if (!this.user)
            return "...";
        return `${this.user.surname} ${this.user.name} ${this.user.patronymic} ${this.amplua}`;
    }
    get profile() {
        if (!this.user) {
            return {
                main: [],
                contacts: [],
            };
        }
        return {
            main: [
                { title: "Фамилия:", value: this.user.surname },
                { title: "Имя:", value: this.user.name },
                { title: "Отчество:", value: this.user.patronymic },
                {
                    title: "День рождения:",
                    value: moment(this.user.birthdate).tz("Europe/Moscow").format("DD.MM.YYYY"),
                },
                { title: "Номер билета:", value: this.user.idRfsl },
                {
                    title: "Срок действия билета:",
                    value: moment(this.user.expiryDate).tz("Europe/Moscow").format("DD.MM.YYYY"),
                },
                {
                    title: "Дата вступления:",
                    value: moment(this.user.entryDate).tz("Europe/Moscow").format("DD.MM.YYYY"),
                },
                {
                    title: "Спортивное звание/Разряд:",
                    value: this.user.sportsCategory === null || (this.user.sportsCategory && !this.user.sportsCategory.sportsCategory)
                        ? "Не указано"
                        : this.user.sportsCategory.sportsCategory.category,
                },
            ],
            contacts: [
                {
                    title: "Регион:",
                    value: this.user.address.region ? this.user.address.region.name : "Не указан",
                },
                {
                    title: "Город:",
                    value: this.user.address.city ? this.user.address.city.name : "Не указан",
                },
                { title: "Телефон:", value: this.user.phone || "Не указан" },
                { title: "E-mail:", value: this.user.email || "Не указан" },
            ],
        };
    }
    gotoProfile() {
        this.$router.push({ name: "user-profile" });
    }
    async mounted() {
        await this.$store.dispatch(UserModule.types.actions.WAIT_FOR_USER_LOADING);
        if (!this.user.profile && !this.user.updateRequest) {
            this.fillProfileDialog.show = true;
        }
    }
};
UserShowcase = __decorate([
    Component({
        components: {
            ApproveDialog,
        },
    })
], UserShowcase);
export default UserShowcase;
